import React from 'react'
import { Link } from 'gatsby'
import { SearchIcon, AcademicCapIcon, HomeIcon, SparklesIcon, MailIcon } from '@heroicons/react/outline'

const Mobilebar = ()  => (
  <nav className="w-full fixed left-0 bottom-0 lg:hidden bg-site-color-brand-1 flex flex-wrap items-center justify-center z-50 py-2 px-4 border-t border-gray-500">
    <div className="relative">
      <div className="flex space-x-4">
        <Link
          to="/"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <HomeIcon className="block h-6 w-6 flex-shrink-0" aria-hidden="true" />
        </Link>
        <Link
          to="/jobs"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <SearchIcon className="block h-6 w-6 flex-shrink-0" aria-hidden="true" />
        </Link>
        <Link
          to="/companies"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <SparklesIcon className="block h-6 w-6 flex-shrink-0" aria-hidden="true" />
        </Link>
        <Link
          to="/learn"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <AcademicCapIcon className="block h-6 w-6 flex-shrink-0" aria-hidden="true" />
        </Link>
        <Link
          to="/newsletter"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <MailIcon className="block h-6 w-6 flex-shrink-0" aria-hidden="true" />
        </Link>
      </div>
    </div>
  </nav>
)

export default Mobilebar