import React from 'react'
import Header from './common/Header'
import styled from 'styled-components'
import Sidebar from './common/Sidebar'
import Mobilebar from './common/Mobilebar'

const StyledDiv = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  overflow: hidden;
`

const StyledMain = styled.main`
  height: 100%;
  padding: 8px;
  flex-grow: 1;
  margin-left: 280px;

  @media (max-width:768px) {
    margin-left: 0px;
  }
`

const Layout = ({ children }) => (
  <>
    <StyledDiv>
      <Header />
      <Sidebar />
      <Mobilebar />
      <StyledMain>
        {children}
      </StyledMain>
    </StyledDiv>
  </>
)

export default Layout