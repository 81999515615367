import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { SearchIcon, AcademicCapIcon, SparklesIcon, MailIcon } from '@heroicons/react/outline'
import { SocialIcon } from 'react-social-icons'

const StyledNav = styled.nav`
  width: 280px;
`

const Sidebar = ()  => (
  <StyledNav className="hidden lg:flex lg:left-0 lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-nowrap lg:overflow-hidden bg-site-color-brand-1 flex-wrap items-center justify-between z-40 py-6 px-4">
    <div className="relative h-full">
      <Link to="/">
        <div className="flex items-center">
          <div>
            <StaticImage
              src="../../images/crd-icon.png"
              alt="Remote crypto dev jobs"
              placeholder="tracedSVG"
              layout="fixed"
              width={40}
              height={40}
            />
          </div>
          <div className="hidden md:flex items-center mb-2 ml-3">
            <h2 className="text-gray-300 text-2xl">
              crypto<span className="font-bold">remote</span>
            </h2>
            <p className="uppercase text-yellow-200 text-xs ml-3 tracking-wider">Beta</p>
          </div>
        </div>
      </Link>

      <div className="flex flex-col mt-16 space-y-4">
        <Link
          to="/jobs"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <SearchIcon className="block h-8 w-8 flex-shrink-0 mr-3" aria-hidden="true" /> Find Job
        </Link>
        <Link
          to="/companies"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <SparklesIcon className="block h-8 w-8 flex-shrink-0 mr-3" aria-hidden="true" /> Companies
        </Link>
        <Link
          to="/learn"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <AcademicCapIcon className="block h-8 w-8 flex-shrink-0 mr-3" aria-hidden="true" /> Learn Web3
        </Link>
        <Link
          to="/newsletter"
          className="w-full text-gray-300 hover:bg-site-color-brand-2 hover:text-white flex items-center px-3 py-2 rounded-md text-lg font-semibold"
          activeClassName="bg-site-color-brand-2"
        >
          <MailIcon className="block h-8 w-8 flex-shrink-0 mr-3" aria-hidden="true" /> Subscribe
        </Link>
      </div>

      <div className="absolute left-0 bottom-0 right-0 mt-16">
        <div className="space-x-3">
          <SocialIcon network="twitter" url="https://twitter.com/cryptoremotedev" bgColor="#4b5563" style={{ height: 40, width: 40 }} />
        </div>
      </div>
    </div>
  </StyledNav>
)

export default Sidebar