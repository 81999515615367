import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <div className="bg-transparent flex items-center justify-between p-4">
    <div>
      <Link to="/">
        <StaticImage
          src="../../images/crd-icon.png"
          alt="Remote crypto dev jobs"
          placeholder="tracedSVG"
          layout="fixed"
          width={40}
          height={40}
        />
      </Link>
    </div>

    <div className="space-x-3">
      <Link
        to="/newsletter"
        className="text-gray-300 bg-gray-900 bg-opacity-40 hover:bg-gray-700 hover:text-white px-4 py-2 rounded-md text-sm font-medium"
        activeClassName="bg-gray-700"
      >
        Subscribe
      </Link>
      <Link
        to="/post-a-job"
        className="text-gray-300 bg-gray-900 bg-opacity-40 hover:bg-gray-700 hover:text-white px-4 py-2 rounded-md text-sm font-medium"
        activeClassName="bg-gray-700"
      >
        Post Job
      </Link>
    </div>
  </div>
)

export default Header